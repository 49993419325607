<template>
<div class="container">
  <div class="row">
    <div class="col-sm-4" v-for="city in cities" :key="city">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title">{{city}}</h5>
          <p class="card-text"></p>
          <div class="card-footer text-center">
            <div class="btn-group btn-group-lg">
          <a class="btn btn-primary btn-md center-block" @click="quizzes(city)">Quiz</a> 
          <a class="btn btn-primary btn-md center-block" @click="news(city)">News</a>
          <!--a class="btn btn-primary btn-md center-block" @click="users(city)">Users</a-->
          <!--a class="btn btn-primary btn-md center-block" @click="stats(city)">Stats</a-->
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Dashboard",
  data: function(){ 
    return { 
      cities:this.$store.getters.cities }
  },
  components: {},
  methods: {
    news: function(city) {
      console.log("VADO A",city)
      this.$router.push("/news/"+city);
    },
    quizzes: function(city) {
      this.$router.push("/quizzes/"+city);
    },
    users: function(city) {
      this.$router.push("/users/"+city);
    },
    stats: function(city) {
      this.$router.push("/stats/"+city);
    }
  }
  
};
</script>
